import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'

import { SocialLink } from '@/features/links'
import { FooterLogo } from '@/features/logo'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

export const Footer = (): JSX.Element => {
  const { datoCmsMetaRecord: data } =
    useStaticQuery<Queries.FooterQuery>(graphql`
      query Footer {
        datoCmsMetaRecord {
          address
          phone
          email
          socials {
            ...SocialLink
          }
        }
      }
    `)
  const styles = {
    section: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: ${colors.navyDark};
      color: #fff;
      padding: var(--row-54) var(--margin);
    `,
    contact: css`
      color: ${colors.navyLighter};
      text-transform: uppercase;
      font-family: var(--ff-good-wide);
      font-size: var(--fs-14);
      text-align: center;
      line-height: 1.25;
      letter-spacing: 0.05em;
      ${mq().ms} {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      a {
        display: inline-block;
        transition: color 300ms ease;
        padding-left: 0.333em;
        padding-right: 0.333em;
        color: currentColor;
        text-decoration: none;
        ${mq().ms} {
          padding-bottom: 0.75em;
        }
        @media (hover: hover) {
          &:hover {
            color: ${colors.tealLight};
          }
        }
      }
    `,
    verticleBar: css`
      display: inline;
      ${mq().ms} {
        display: none;
      }
    `,
    logo: css`
      width: 22em;
      margin-bottom: 3em;
      ${mq().ms} {
        width: 18em;
      }
    `,
    socials: css`
      font-size: var(--fs-30);
      margin-top: 1em;
      display: flex;
      column-gap: 0.5em;
    `,
    socialLink: css`
      transition: color 300ms ease;
      color: ${colors.navyLighter};
      @media (hover: hover) {
        &:hover {
          color: ${colors.tealLight};
        }
      }
    `,
  }
  return (
    <section css={styles.section}>
      <FooterLogo
        css={styles.logo}
        fill="#fff"
      />
      <div css={styles.contact}>
        {data?.address && (
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
              data?.address || ''
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            {data?.address}
          </a>
        )}
        {data?.address && (data.phone || data.email) && (
          <span css={styles.verticleBar}>{` \u007C `}</span>
        )}
        {data?.phone && (
          <a
            href={`tel:${data?.phone?.replace(/\D/, '')}`}
            target="_blank"
            rel="noreferrer"
          >
            {data?.phone}
          </a>
        )}
        {data?.phone && data.email && (
          <span css={styles.verticleBar}>{` \u007C `}</span>
        )}
        {data?.email && (
          <a
            href={`mailto:${data.email}`}
            target="_blank"
            rel="noreferrer"
          >
            {data.email}
          </a>
        )}
      </div>
      <div css={styles.socials}>
        {data?.socials?.map(link => (
          <SocialLink
            css={styles.socialLink}
            data={link}
            key={link?.id}
          />
        ))}
      </div>
    </section>
  )
}
